<!-- eslint-disable vue/no-lone-template -->
<template>
  <StandardModal
    id="static-data-modal"
    v-model="shown"
    size="lg"
    :should-show-overlay="isDeleteLoading"
    :on-confirm="onOk"
    :on-cancel="() => {}"
    :should-show-warning-confirmation-slot="shouldShowDeleteConfirmationSlot"
    static
    @show="onShow"
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #header>
      <b-row v-if="portfolioTreeItem">
        <b-col>
          <b-form-group
            v-if="editing && isPortfolioTreeSubportfolio(portfolioTreeItem)"
            class="mb-0"
          >
            <b-form-input
              id="name-input"
              v-model="portfolioTreeItem.name"
              :disabled="shouldModalsBeDisabled"
              :state="isNameValid"
              :placeholder="translate({ path: 'MODALS.STATIC_DATA_MODAL.PORTFOLIO_NAME' })"
              autofocus
              size="sm"
              @blur="handleTitleBlur"
              @keyup.enter="onOk"
            />
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback
              id="name-input-feedback"
              class="position-absolute"
            >
              {{ translate({ path: 'FORM.REQUIRED_FIELD' }) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <span
            v-else
            class="d-flex align-items-center"
            :class="isPortfolioTreeSubportfolio(portfolioTreeItem) ? 'cursor-pointer' : ''"
            @click="handleTitleClick"
          >
            <h4 class="mt-2 text-primary text-ellipsis">{{ modalTitle }}</h4>
            <icon
              v-if="isPortfolioTreeSubportfolio(portfolioTreeItem) && !shouldModalsBeDisabled"
              icon="pen"
              class="text-info ml-2"
            />
          </span>
        </b-col>
        <ExportPortfolioDropdown
          v-if="
            masterPortfolioTree &&
            !isMaster &&
            isPortfolioTreeSubportfolio(portfolioTreeItem) &&
            portfolioTreeItem.components.length > 0
          "
          class="align-items-center"
          :subportfolio-to-be-exported="portfolioTreeItem"
        />
      </b-row>
    </template>

    <template>
      <div v-if="portfolioTreeItem">
        <PortfolioStaticData
          v-if="masterPortfolioTree && isPortfolioTreeSubportfolio(portfolioTreeItem)"
          ref="portfolioStaticData"
          :master-portfolio-obj.sync="masterPortfolioTree"
          :subportfolio-obj="portfolioTreeItem"
          :disabled="shouldModalsBeDisabled"
          :is-master="isMaster"
          :is-vue-query-enabled="shown"
          :inherited-from="inheritedFrom"
          @update-portfolio-tree="updatePortfolioTreeItem"
        />
        <StrategyStaticData
          v-else-if="isPortfolioTreeStrategy(portfolioTreeItem)"
          :portfolio-tree-item="portfolioTreeItem"
          :disabled="shouldModalsBeDisabled"
          :is-inactive="isInactive"
          :inherited-from="inheritedFrom"
        />
      </div>
    </template>

    <template #footer="{ cancel, ok }">
      <div
        :class="
          isOnlyCancelButtonShown
            ? 'd-flex justify-content-around align-items-center'
            : 'd-flex justify-content-between align-items-center'
        "
      >
        <b-button
          class="btn-analysis-toolbar"
          @click="cancel"
        >
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </b-button>
        <b-button
          v-if="showDelete"
          variant="danger"
          class="btn-analysis-toolbar"
          :disabled="shouldDisableDeletion"
          @click="toggleDelete"
        >
          {{ translate({ path: 'GLOBAL.DELETE' }) }}
        </b-button>
        <b-button
          v-if="showUndelete"
          variant="outline-danger"
          class="btn-analysis-toolbar"
          :disabled="isNewSubportfolio"
          @click="toggleDelete"
        >
          {{ translate({ path: 'GLOBAL.UNDO_DELETE' }) }}
        </b-button>
        <b-button
          v-if="showSuspend"
          variant="outline-info"
          class="btn-analysis-toolbar"
          :disabled="isNewSubportfolio"
          @click="toggleSuspend(true)"
        >
          {{ translate({ path: 'GLOBAL.SUSPEND' }) }}
        </b-button>
        <b-button
          v-if="showUnsuspend"
          variant="outline-info"
          class="btn-analysis-toolbar"
          :disabled="isNewSubportfolio"
          @click="toggleSuspend(false)"
        >
          {{ translate({ path: 'GLOBAL.UNSUSPEND' }) }}
        </b-button>
        <!-- Once a basket is submitted for approval, it should be immutable. -->
        <b-button
          v-if="isApplyButtonShown"
          variant="info"
          class="btn-analysis-toolbar"
          :disabled="isApplyButtonDisabled"
          @click="ok"
        >
          {{ translate({ path: 'GLOBAL.APPLY' }) }}
        </b-button>
      </div>
    </template>

    <template #footer-confirmation>
      <PortfolioDeleteConfirmation
        v-if="portfolioTreeItem && isPortfolioTreeSubportfolio(portfolioTreeItem)"
        :portfolio-name="portfolioTreeItem?.name ?? ''"
        :loading="isDeleteLoading"
        @submit="deleteMaster"
        @cancel="shouldShowDeleteConfirmationSlot = false"
      />
    </template>
  </StandardModal>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, Ref, ref, set, watch } from 'vue';
import PortfolioStaticData from './PortfolioStaticData.vue';
import StrategyStaticData from './StrategyStaticData.vue';
import {
  findParentSubportfolio,
  findTreeComponent,
  createSubportfolio,
  isItemInactive,
  isPortfolioTreePointerFn,
  isBasketTypePortfolioFn,
} from '@/utils/portfolioTree';
import {
  IPortfolioTreeStrategy,
  IPortfolioTreeSubportfolio,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { cloneDeep, omit } from 'lodash';
import useTranslation from '@/composables/useTranslation';
import { Status } from '@/constants/Status';
import usePortfolioTree, { getBasketStatus } from '@/composables/usePortfolioTree';
import { OpenStaticDataModalEvent, PortfolioControllerEvents } from '@/types/Injectables';
import useGlobalEventBus, { GlobalEventTypes } from '@/composables/useGlobalEventBus';
import PortfolioDeleteConfirmation from './PortfolioDeleteConfirmation.vue';
import { usePortfolioInfo } from './usePortfolioInfo';
import { PortfolioType } from '@/types/portfolio';
import useShowNextPortfolio from '@/composables/useShowNextPortfolio';
import { SaveChangesStore, SaveItemUniqueKeys } from '@/store/modules/SaveChangesStore';
import { Emitter } from 'mitt';
import { useToasts } from '@/composables/useToasts';
import ExportPortfolioDropdown from './ExportPortfolioDropdown.vue';
import useAppMetrics from '@/composables/useAppMetrics';
import useFxConversion from '@/composables/useFxConversion';
import { isStrategy } from '@/utils/strategy';
import { ISidebarRow } from '@/types/ISidebarRow';
import useStrategyMask from '@/composables/useStrategyMask';
import { usePortfolioTreeOriginalIdentifier } from '@/composables/useCorrectIdentifier';
import { usePortfolioSidebar } from '@/composables/usePortfolioSidebar';
import { useRouteRef } from '@/composables/useRouter';
import useRouteChecks from '@/composables/useRouteChecks';
import useOverlayData from '@/composables/useOverlayData';
import { useDeletePortfolioTree } from '@/composables/queries/usePortfolioTreeData';
import { useDeletePortfoliosAndAssociatedFiles } from '@/composables/queries/useFileService';
import { usePortfolioVersionPreferences } from '@/composables/usePortfolioVersionPreferences';
import { PortfolioVersionConstants } from '@/constants/PortfolioVersionConstants';
import { useBasketPermissions } from '@/composables/useBasketPermissions';

const usePortfolioSuspend = (
  item: Ref<IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | null>,
  closeDialog: () => void,
  isParentPointerPortfolio: Ref<boolean>,
) => {
  const { eventBus } = useGlobalEventBus();
  const { isMaster } = usePortfolioInfo(item);
  const route = useRouteRef();
  const { isOnConstituentRisk } = useRouteChecks(route);

  const isInactive = computed(() => isItemInactive(item.value, isOnConstituentRisk.value));
  const isPointerPortfolio = computed(() => isPortfolioTreePointerFn(item.value));

  const isSuspended = computed(() => {
    if (isMaster.value) {
      return false;
    }

    if (!item.value) {
      return false;
    }

    return item.value.suspended === Status.SUSPENDED;
  });

  /**
   * All root in portfolios cannot be suspended. Unless they are pointers/linked
   * All strategies/childs in pointers cannot be suspended, only the root pointer can
   * If inactive, cannot be suspended
   */
  const showSuspend = computed(() => {
    if (isMaster.value) {
      return false;
    }

    if (isParentPointerPortfolio.value) {
      return false;
    }

    if (isPointerPortfolio.value) {
      return true;
    }

    return !isSuspended.value && !isInactive.value;
  });
  const showUnsuspend = computed(() => {
    return isSuspended.value;
  });

  const { removeItem: removeOverlayItem } = useOverlayData();

  /**
   * @Important directly modify vuex itemRef suspended attribute
   */
  const toggleSuspend = (makeSuspended: boolean): void => {
    if (!item.value) return;

    removeOverlayItem({ treeId: item.value.portfolioTreeId });
    set(item.value, 'suspended', makeSuspended ? Status.SUSPENDED : Status.ACTIVE);
    eventBus.emit(PortfolioControllerEvents.SET_PORTFOLIO_TREE_HAS_CHANGES);

    closeDialog();
  };

  return {
    showSuspend,
    showUnsuspend,
    toggleSuspend,
  };
};

const usePortfolioDelete = ({
  item,
  closeDialog,
  forceCloseDialog,
  isModalShown,
  shouldShowDeleteConfirmationSlot,
  isParentPointerPortfolio,
}: {
  item: Ref<IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | null>;
  closeDialog: () => void;
  forceCloseDialog: () => void;
  isModalShown: Ref<boolean>;
  shouldShowDeleteConfirmationSlot: Ref<boolean>;
  isParentPointerPortfolio: Ref<boolean>;
}) => {
  const { errorToast, successToast } = useToasts();
  const { isMaster, masterPortfolioTree } = usePortfolioInfo(item);
  const { eventBus } = useGlobalEventBus();
  const { translate } = useTranslation();
  const { showNextPortfolio } = useShowNextPortfolio({ enabled: isModalShown });

  const isPointerPortfolio = computed(() => isPortfolioTreePointerFn(item.value));

  const isDeleted = computed(() => {
    if (item.value === null) {
      return false;
    }

    if (isMaster.value) {
      return false;
    }

    return item.value.suspended === Status.SOFT_DELETE;
  });

  /**
   * if it is pointer or type='start_fresh', only the root portfolio can be deleted
   * all type='constituent' cannot be deleted
   * all type='portfolio' can be deleted
   * If inactive and type='portfolio', it can still be deleted. otherwise, it cannot be deleted
   */
  const showDelete = computed(() => {
    if (!item.value) {
      return false;
    }

    if (isDeleted.value) {
      return false;
    }

    if (!masterPortfolioTree.value) {
      return false;
    }

    if (isParentPointerPortfolio.value) {
      return false;
    }

    if (masterPortfolioTree.value.portfolioTree.type === PortfolioType.START_FRESH) {
      if (isMaster.value || isPointerPortfolio.value) return true;
      return false;
    }

    if (
      masterPortfolioTree.value.portfolioTree.type === PortfolioType.PORTFOLIO ||
      masterPortfolioTree.value.portfolioTree.type === PortfolioType.EQUITY_BASKET ||
      masterPortfolioTree.value.portfolioTree.type === PortfolioType.QIS_BASKET
    ) {
      return true;
    }

    return false;
  });

  const showUndelete = computed(() => {
    return isDeleted.value;
  });

  const { removeItem: removeOverlayItem } = useOverlayData();

  /**
   * This should handle delete of sub-portfolio and master portfolio.
   * See comments on how we handle both cases.
   */
  const toggleDelete = () => {
    if (!item.value) {
      return;
    }

    // Show deleting confirm message if user is trying to delete the mater portfolio
    if (isMaster.value) {
      shouldShowDeleteConfirmationSlot.value = true;
      return;
    }

    removeOverlayItem({ treeId: item.value.portfolioTreeId });
    set(item.value, 'suspended', isDeleted.value ? Status.ACTIVE : Status.SOFT_DELETE);
    eventBus.emit(PortfolioControllerEvents.SET_PORTFOLIO_TREE_HAS_CHANGES);

    closeDialog();
  };

  const deletePortfolioMutation = useDeletePortfolioTree({
    onSuccess() {
      if (item.value === null) {
        return;
      }

      successToast(
        translate({
          path: isPortfolioTreeSubportfolio(item.value)
            ? 'MODALS.STATIC_DATA_MODAL.PORTFOLIO_SUCCEED_DELETE_TOAST'
            : 'MODALS.STATIC_DATA_MODAL.STRATEGY_SUCCEED_DELETE_TOAST',
        }),
      );
    },
    onError() {
      if (item.value === null) {
        return;
      }

      errorToast(
        translate({
          path: isPortfolioTreeSubportfolio(item.value)
            ? 'MODALS.STATIC_DATA_MODAL.PORTFOLIO_FAILED_DELETE_TOAST'
            : 'MODALS.STATIC_DATA_MODAL.STRATEGY_FAILED_DELETE_TOAST',
        }),
      );
    },
  });

  const deletePortfoliosAndAssociatedFilesMutation = useDeletePortfoliosAndAssociatedFiles({
    onSuccess() {
      if (item.value === null) {
        return;
      }

      successToast(
        translate({
          path: isPortfolioTreeSubportfolio(item.value)
            ? 'MODALS.STATIC_DATA_MODAL.PORTFOLIO_SUCCEED_DELETE_TOAST'
            : 'MODALS.STATIC_DATA_MODAL.STRATEGY_SUCCEED_DELETE_TOAST',
        }),
      );
    },
    onError() {
      if (item.value === null) {
        return;
      }

      errorToast(
        translate({
          path: isPortfolioTreeSubportfolio(item.value)
            ? 'MODALS.STATIC_DATA_MODAL.PORTFOLIO_FAILED_DELETE_TOAST'
            : 'MODALS.STATIC_DATA_MODAL.STRATEGY_FAILED_DELETE_TOAST',
        }),
      );
    },
  });

  const deleteMaster = async () => {
    const portfolioTreeOriginalIdentifier = usePortfolioTreeOriginalIdentifier();
    if (item.value === null || portfolioTreeOriginalIdentifier.value === null) {
      return;
    }

    if (!shouldShowDeleteConfirmationSlot.value) {
      return;
    }

    if (
      item.value.type === PortfolioType.PORTFOLIO ||
      item.value.type === PortfolioType.EQUITY_BASKET ||
      item.value.type === PortfolioType.QIS_BASKET
    ) {
      // For `type = portfolio or equity basket or qis_basket` just delete the tree
      await deletePortfolioMutation.mutateAsync(portfolioTreeOriginalIdentifier.value);
    } else {
      // For `type = start_fresh` need to delete the files associated with the portfolio and the tree
      // This is the same delete function as the dropzone
      await deletePortfoliosAndAssociatedFilesMutation.mutateAsync([
        {
          slug: portfolioTreeOriginalIdentifier.value,
        },
      ]);
    }

    // Set portfolio not modified so that the navigation won't trigger "Do you really want to leave?" dialog
    SaveChangesStore.RemoveItemsToSave({
      keys: [SaveItemUniqueKeys.PORTFOLIO_TREE, SaveItemUniqueKeys.PORTFOLIO_TREE_DRAFT],
    });

    forceCloseDialog();

    if (isPortfolioTreeSubportfolio(item.value)) {
      showNextPortfolio(item.value.type);
    }
  };

  const isDeleteLoading = computed(() => {
    return deletePortfoliosAndAssociatedFilesMutation.isPending.value || deletePortfolioMutation.isPending.value;
  });

  return {
    showDelete,
    showUndelete,
    toggleDelete,
    isDeleteLoading,
    deleteMaster,
  };
};

export default defineComponent({
  name: 'StaticDataModal',
  components: {
    PortfolioStaticData,
    StrategyStaticData,
    PortfolioDeleteConfirmation,
    ExportPortfolioDropdown,
  },
  setup() {
    const { eventBus } = useGlobalEventBus();
    const { translate } = useTranslation();
    const { masterPortfolioTree, itemUnderAnalysis } = usePortfolioTree();
    const { shouldDisableEditingOnBasket } = getBasketStatus(masterPortfolioTree);
    const { sidebarRowsTree, pushSidebarRowConfiguration } = usePortfolioSidebar();
    const route = useRouteRef();
    const { isOnConstituentRisk } = useRouteChecks(route);

    const { isLiveVersion } = usePortfolioVersionPreferences();

    // DATA
    // this static data modal only modify tree level itself info, does not affect children info
    const shown = ref(false);

    const isParentPointerPortfolio = ref(false);

    /**
     * Is the title currently in editing mode, if yes show input
     */
    const editing = ref(false);

    /**
     * If true, then canceling will not add the subportfolio to the tree
     */
    const isNewSubportfolio = ref(false);

    const portfolioTreeItem = ref<IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | null>(null);

    /**
     * Update the status of the component that was toggled suspend or delete
     */
    const updateSidebarRowsTree = () => {
      if (!masterPortfolioTree.value || !portfolioTreeItemId.value) return;

      let sidebarRowsTreeClone = cloneDeep(sidebarRowsTree.value);
      const component = findTreeComponent(masterPortfolioTree.value.portfolioTree, portfolioTreeItemId.value);

      const currentItem = sidebarRowsTreeClone.find((x) => portfolioTreeItemId.value === x.portfolioTreeId);
      if (currentItem && component) {
        currentItem.isSuspended = isOnConstituentRisk.value
          ? false
          : component.suspended !== Status.ACTIVE || component.status > Status.SUSPENDED;
      }

      sidebarRowsTree.value = sidebarRowsTreeClone;
    };

    /**
     * On title click, turn on editing mode
     */
    const handleTitleClick = () => {
      if (portfolioTreeItem.value && isPortfolioTreeSubportfolio(portfolioTreeItem.value)) {
        editing.value = true;
      }
    };

    /**
     * On blur event, turn off editing
     */
    const handleTitleBlur = () => {
      if (isNameValid.value === false) return;
      editing.value = false;
    };

    /**
     * Apply button should be disabled if:
     * - portfolioTreeItem is not defined
     * - portfolioTreeItem is a subportfolio and it has no name
     * - editing is disabled
     */
    const isApplyButtonDisabled = computed(
      () =>
        (portfolioTreeItem.value &&
          isPortfolioTreeSubportfolio(portfolioTreeItem.value) &&
          !portfolioTreeItem.value.name) ||
        isLiveVersion.value ||
        shouldDisableEditingOnBasket.value,
    );

    /**
     * Determine if the apply button should be shown
     * if the item is inactive or it's part of a pointer portfolio, except for the root, it will be hidden
     */
    const isApplyButtonShown = computed(
      () => !isInactive.value && !isPortfolioTreePointerFn(portfolioTreeItem.value) && !isParentPointerPortfolio.value,
    );

    /**
     * Determine if only the cancel button should be shown
     * if yes, make sure that it is align horizontally centered (i.e. justify-content-around)
     */
    const isOnlyCancelButtonShown = computed(
      () =>
        !isApplyButtonShown.value &&
        !showDelete.value &&
        !showUndelete.value &&
        !showSuspend.value &&
        !showUnsuspend.value,
    );

    /**
     * Determine if the content of the modals should be disabled
     */
    const shouldModalsBeDisabled = computed(() => {
      if (!portfolioTreeItem.value || !masterPortfolioTree.value) return true;

      // if the constituent risk portfolio is in live mode, disable the modal
      if (isLiveVersion.value) return true;

      // In the case of a subportfolio, disabled if the deleteConfirmation slot is on, or if it is pointer portfolio
      if (isPortfolioTreeSubportfolio(portfolioTreeItem.value)) {
        return shouldShowDeleteConfirmationSlot.value || !!portfolioTreeItem.value.pointerSlug;
      } else {
        // In the case of a strategy, disabled if the parent item is pointer portfolio
        const parent = findParentSubportfolio(
          masterPortfolioTree.value.portfolioTree,
          portfolioTreeItem.value.portfolioTreeId,
        );
        return !!parent?.pointerSlug;
      }
    });

    /**
     * Determine if the error message should be shown
     */
    const isInactive = computed(() => isItemInactive(portfolioTreeItem.value));

    const onOk = async () => {
      if (!masterPortfolioTree.value || !shown.value || isApplyButtonDisabled.value) return;

      if (isNewSubportfolio.value) {
        addNewSubportfolioSidebarRows();
      } else {
        if (!portfolioTreeItemId.value) return;
        const component = findTreeComponent(masterPortfolioTree.value.portfolioTree, portfolioTreeItemId.value);

        if (!component || !portfolioTreeItem.value) return;
        let clonedItem:
          | Omit<IPortfolioTreeSubportfolio, 'components'>
          | Omit<IPortfolioTreeStrategy, 'strategy'>
          | null = null;

        if (isPortfolioTreeSubportfolio(portfolioTreeItem.value)) {
          clonedItem = cloneDeep(omit(portfolioTreeItem.value, 'components'));
        } else {
          clonedItem = cloneDeep(omit(portfolioTreeItem.value, 'strategy'));
        }

        // set value to find portfolio tree
        Object.entries(clonedItem).forEach(([key, value]) => {
          set(component, key, value);
        });

        updateSidebarRowsTree();
      }
      eventBus.emit(PortfolioControllerEvents.SET_PORTFOLIO_TREE_HAS_CHANGES);

      shown.value = false;
    };

    const findNewSubportfolioParent = () => {
      if (!masterPortfolioTree.value || !itemUnderAnalysis.value || isStrategy(itemUnderAnalysis.value)) {
        return;
      }

      const currentComponent = findTreeComponent(
        masterPortfolioTree.value.portfolioTree,
        itemUnderAnalysis.value.portfolioTreeId,
      );
      if (currentComponent && isPortfolioTreeSubportfolio(currentComponent)) {
        return currentComponent;
      }
      // If the itemUnderAnalysis is a strategy, we want to add the subportfolio to its parent
      return findParentSubportfolio(masterPortfolioTree.value.portfolioTree, itemUnderAnalysis.value.portfolioTreeId);
    };

    const addNewSubportfolioSidebarRows = () => {
      if (!portfolioTreeItem.value) {
        return;
      }

      let componentToUse = findNewSubportfolioParent();
      if (!componentToUse) return;

      /**
       * @Important we directly change subportfolio for vuex
       */
      componentToUse.components.unshift(portfolioTreeItem.value);

      const rows: ISidebarRow[] = [
        {
          portfolioTreeId: portfolioTreeItem.value.portfolioTreeId,
          children: [],
        },
      ];

      pushSidebarRowConfiguration({
        rows,
        parentTreeId: componentToUse.portfolioTreeId,
      });
    };

    const forceCloseDialog = () => {
      shown.value = false;
    };
    const shouldShowDeleteConfirmationSlot = ref(false);

    const { isMaster } = usePortfolioInfo(portfolioTreeItem);
    const { showSuspend, showUnsuspend, toggleSuspend } = usePortfolioSuspend(
      portfolioTreeItem,
      onOk,
      isParentPointerPortfolio,
    );
    const { showDelete, showUndelete, toggleDelete, deleteMaster, isDeleteLoading } = usePortfolioDelete({
      item: portfolioTreeItem,
      closeDialog: onOk,
      forceCloseDialog,
      isModalShown: shown,
      shouldShowDeleteConfirmationSlot,
      isParentPointerPortfolio,
    });

    const portfolioTreeItemId = ref<string | null>(null);

    const { getStrategyMask } = useStrategyMask();

    const modalTitle = computed(() => {
      if (!portfolioTreeItem.value) return '';
      return getStrategyMask(portfolioTreeItem.value);
    });

    /**
     * False is invalid state
     * Null is neutral state
     */
    const isNameValid = computed(() => {
      if (
        portfolioTreeItem.value &&
        isPortfolioTreeSubportfolio(portfolioTreeItem.value) &&
        portfolioTreeItem.value.name === ''
      )
        return false;
      return null;
    });

    /**
     * Find the name of first ancestor where the fx conversion is not inherited
     * Return the name of that ancestor
     */
    const findInheritedFrom = (portfolioTreeId: string): string | undefined => {
      if (!masterPortfolioTree.value) return;
      // Find the parent of portfolioTreeId
      const parent = findParentSubportfolio(masterPortfolioTree.value.portfolioTree, portfolioTreeId);
      if (!parent) return;
      // Check if parent is inherited
      if (parent.isInherited) {
        // If parent is master, then return the name
        if (parent.portfolioTreeId === masterPortfolioTree.value.portfolioId) return parent.name;
        // Go find the inherited name from an ancestor further up
        return findInheritedFrom(parent.portfolioTreeId);
      } else {
        // If not inherited, return name
        return parent.name;
      }
    };

    /**
     * Find the name of the ancestor which applies the fx conversion
     */
    const inheritedFrom = computed(() => {
      // If portfolioTreeItem is not defined, return undefined
      if (!portfolioTreeItem.value) return;

      if (isNewSubportfolio.value) {
        let parent = findNewSubportfolioParent();
        if (!parent || !masterPortfolioTree.value) return;
        if (parent.isInherited) {
          // If parent is master, then return the name
          if (parent.portfolioTreeId === masterPortfolioTree.value.portfolioId) return parent.name;
          // Go find the inherited name from an ancestor further up
          return findInheritedFrom(parent.portfolioTreeId);
        } else {
          // If not inherited, return name
          return parent.name;
        }
      }
      return findInheritedFrom(portfolioTreeItem.value.portfolioTreeId);
    });

    // LIFECYCLE EVENTS
    const { fxType, toCurrency } = useFxConversion();
    const onShow = () => {
      if (isNewSubportfolio.value) {
        let subportfolio = createSubportfolio(
          translate({ path: 'GLOBAL.NEW_SUBPORTFOLIO' }),
          masterPortfolioTree.value,
          toCurrency.value,
          fxType.value,
        );
        if (!subportfolio) return;

        portfolioTreeItem.value = subportfolio;
      } else {
        if (!masterPortfolioTree.value || !portfolioTreeItemId.value) return;
        const component = findTreeComponent(masterPortfolioTree.value.portfolioTree, portfolioTreeItemId.value);
        if (!component) return;

        portfolioTreeItem.value = cloneDeep(component);
      }
    };

    // Reset when portfolio is rerun
    watch([shown], () => {
      shouldShowDeleteConfirmationSlot.value = false;
    });

    const startShowTimer = ref<number | null>(null);

    const openModal = ({
      portfolioTreeId,
      timerId,
      isPointerPortfolio,
      shouldEdit = false,
      isNewSubportfolio: isNew = false,
    }: {
      portfolioTreeId?: string;
      timerId?: number;
      isPointerPortfolio?: boolean;
      shouldEdit?: boolean;
      isNewSubportfolio?: boolean;
    }) => {
      if (timerId !== undefined) {
        startShowTimer.value = timerId;
      }

      isNewSubportfolio.value = isNew;
      portfolioTreeItemId.value = portfolioTreeId ?? null;
      shown.value = true;
      editing.value = shouldEdit;
      isParentPointerPortfolio.value = isPointerPortfolio ?? false;
    };

    onMounted(() => {
      (eventBus as Emitter<OpenStaticDataModalEvent>).on(GlobalEventTypes.OPEN_STATIC_DATA_MODAL, openModal);
    });
    onBeforeUnmount(() => {
      (eventBus as Emitter<OpenStaticDataModalEvent>).off(GlobalEventTypes.OPEN_STATIC_DATA_MODAL, openModal);
    });

    const { endTimer, trackModalShown } = useAppMetrics();

    const onShown = () => {
      if (startShowTimer.value !== null) {
        const showModal = endTimer(startShowTimer.value, 'show StaticDataModal');
        if (showModal) {
          trackModalShown('StaticDataModal', {
            timeElapsed: showModal.timeElapsed,
          });
        }
      }
    };
    const onHidden = () => {
      startShowTimer.value = null;
    };

    const portfolioStaticData = ref<InstanceType<typeof PortfolioStaticData> | null>(null);

    const updatePortfolioTreeItem = (newPortfolioTree: IPortfolioTreeSubportfolio) => {
      portfolioTreeItem.value = newPortfolioTree;
    };

    const isBasket = isBasketTypePortfolioFn(masterPortfolioTree);

    const { version: portfolioVersion } = usePortfolioVersionPreferences();

    const { hasSomeBasketSubmissionAccess } = useBasketPermissions();

    /**
     * Disable deletion when:
     * 1. We are viewing the live/provisional version of a portfolio/basket
     * 2. When we are viewing a basket simulation and do not have submission access
     */
    const shouldDisableDeletion = computed(() => {
      if (portfolioVersion.value !== PortfolioVersionConstants.SIMULATION) return true;
      if (isBasket.value) {
        return !hasSomeBasketSubmissionAccess.value;
      }
      return isNewSubportfolio.value;
    });

    return {
      translate,
      isDeleteLoading,
      shown,
      portfolioTreeItem,
      masterPortfolioTree,
      showSuspend,
      showUnsuspend,
      toggleSuspend,
      onShow,
      onOk,

      isMaster,

      showDelete,
      showUndelete,
      toggleDelete,
      deleteMaster,
      isPortfolioTreeSubportfolio,
      isApplyButtonDisabled,
      isApplyButtonShown,
      isOnlyCancelButtonShown,
      isInactive,
      isPortfolioTreePointerFn,
      isParentPointerPortfolio,
      isNewSubportfolio,

      modalTitle,
      handleTitleClick,
      handleTitleBlur,
      editing,
      isNameValid,

      inheritedFrom,
      shouldShowDeleteConfirmationSlot,
      shouldModalsBeDisabled,

      onShown,
      onHidden,

      portfolioStaticData,
      updatePortfolioTreeItem,
      isPortfolioTreeStrategy,
      shouldDisableDeletion,
    };
  },
});
</script>
