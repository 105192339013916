import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { cloneDeep } from 'lodash';
import {
  deleteCustomStressTest,
  getCustomStressTest,
  updateCustomStressTest,
  uploadCustomStressTest,
} from '@/api-v2/web/risk-dashboard';
import { RiskTabs, CustomStressTest } from '@/types/analytics/ConstituentRisk';
import { IConstituentRisk, RiskDashboardTableComponentsConstants } from '@/types/IConstituentRisk';

@Module({ dynamic: true, store, name: 'ConstituentRisk', namespaced: true })
class ConstituentRisk extends VuexModule {
  public constituentRiskData: IConstituentRisk = {
    Dates: null,
    RiskTable: null,
    CommodityTreeMap: null,
    CommodityForward: null,
    CommoditySpotLadder: null,
    CommodityTableDelta: null,
    CommodityTableGamma: null,
    CommodityMetricTrack: null,
    CommodityLadderTable: null,
    EquityTreeMap: null,
    EquityBarChart: null,
    EquitySpotLadder: null,
    EquityMetricTrack: null,
    EquityLadderTable: null,
    VarCharts: null,
    VarMetricTrack: null,
    ExposureMetricTrack: null,
    StressTest: null,
    StressTestMetricTrack: null,
    FXChart: null,
    FXMetricTrack: null,
    FXSpotLadder: null,
    FXTable: null,
    FXLadderTable: null,
    IRChart: null,
    IRMetricTrack: null,
    IRSpotLadder: null,
    IRTable: null,
    IRNormalLadderTable: null,
    IRBsLadderTable: null,
    CreditChart: null,
    CreditMetricTrack: null,
    CreditTable: null,
  };
  public fieldsToCall: { tableComponent: RiskDashboardTableComponentsConstants; fields: string[] | undefined } = {
    tableComponent: RiskDashboardTableComponentsConstants.RiskTable,
    fields: undefined,
  };

  public isHistoryMode = false;

  public hasPageOptionsChanged: { [key in RiskTabs]: boolean } = {
    [RiskTabs.VAR]: false,
    [RiskTabs.STRESS_TEST]: false,
    [RiskTabs.EQUITY]: false,
    [RiskTabs.INTEREST_RATE]: false,
    [RiskTabs.CREDIT]: false,
    [RiskTabs.FX]: false,
    [RiskTabs.COMMODITY]: false,
  };

  public customStressTests: CustomStressTest[] = [];

  /**
   * For each tab, change its status to the 'newVal' and update the variable.
   */
  @Mutation
  private SET_HAS_PAGE_OPTIONS_CHANGED({
    newVal,
    resetAll,
  }: {
    newVal?: { tabName: RiskTabs; newVal: boolean }[];
    resetAll?: boolean;
  }): void {
    const clone = cloneDeep(this.hasPageOptionsChanged);
    if (resetAll) {
      Object.keys(clone).forEach((element) => {
        clone[element as RiskTabs] = true;
      });
    } else if (newVal) {
      newVal.forEach((element): void => {
        clone[element.tabName] = element.newVal;
      });
    }
    this.hasPageOptionsChanged = clone;
  }

  /**
   * Determines when the constituent risk data API should be triggered.
   * Should be triggered on new portfolio slug, new date, new unit.
   * @param newVal Object array that includes the tabName and its new boolean value to determine if the API should be used
   */
  @Action({ rawError: true })
  public async SetHasPageOptionsChanged({
    newVal,
    resetAll,
  }: {
    newVal?: { tabName: RiskTabs; newVal: boolean }[];
    resetAll?: boolean;
  }): Promise<void> {
    this.SET_HAS_PAGE_OPTIONS_CHANGED({ newVal, resetAll });
  }

  @Mutation
  private TOGGLE_HISTORY_MODE(shouldTurnOnHistory: boolean): void {
    this.isHistoryMode = shouldTurnOnHistory;
  }
  /**
   * Saves the constituent risk preferences as a cookie every time they are changed
   */
  @Action({ rawError: true })
  public async ToggleHistoryMode(shouldTurnOnHistory: boolean): Promise<void> {
    this.TOGGLE_HISTORY_MODE(shouldTurnOnHistory);
  }

  @Action({ rawError: true })
  public async GetCustomStressTests(): Promise<void> {
    const options = await getCustomStressTest();

    this.GET_CUSTOM_STRESS_TESTS({ options });
  }

  @Mutation
  private GET_CUSTOM_STRESS_TESTS({ options }: { options: CustomStressTest[] }): void {
    this.customStressTests = options;
  }

  @Action({ rawError: true })
  public async SetCustomStressTests(body: CustomStressTest): Promise<void> {
    await uploadCustomStressTest(body);
  }

  @Action({ rawError: true })
  public async UpdateCustomStressTests(params: { attributeId: string; body: CustomStressTest }): Promise<void> {
    await updateCustomStressTest(params);
  }

  @Action({ rawError: true })
  public async DeleteCustomStressTests(attributeId: string): Promise<void> {
    await deleteCustomStressTest(attributeId);
  }
}

export const ConstituentRiskModule = getModule(ConstituentRisk);
