import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';
import { MyLabRouter } from './MyLabRouter';
import { AdminRouter } from './AdminRouter';
import { ArticleRouter } from './ArticleRouter';
import { AuthRouter } from './AuthRouter';
import { PerformanceContributionRouter } from './analytics/PerformanceRouter';
import { SettingsRouter } from './SettingsRouter';
import { ResourcesRouter } from './ResourcesRouter';
import { InsightsRouter } from './InsightsRouter';
import { AnalyticsRouter } from './AnalyticsRouter';
import { StrategiesRouter } from './StrategiesRouter';
import { PortfoliosRouter } from './PortfoliosRouter';
import { DataRouter } from './DataRouter';
import { InactiveRouter } from './InactiveRouter';
import { NoCodeDashboardRouter } from '../no-code-dashboard/router/NoCodeDashboardRouter';

// HSBC whitelabel routers
import { TermsRouter, AboutRouter, PrivacyPolicyRouter } from './whitelabel/hsbc/misc/StaticPagesRouter';
import { StrategiesRouter as StrategiesRouterHSBC } from '@/router/whitelabel/hsbc/StrategiesRouter';
import { PerformanceContributionRouter as PerformanceAttributionRouterHSBC } from '@/router/whitelabel/hsbc/analytics/PerformanceRouter';
import { ArticleRouter as ArticleRouterHSBC } from '@/router/whitelabel/hsbc/ArticleRouter';
import { DataRouter as DataRouterHSBC } from '@/router/whitelabel/hsbc/DataRouter';
import { AdminRouter as AdminRouterHSBC } from '@/router/whitelabel/hsbc/AdminRouter';

// Barclays whitelabel routers
import { ArticleRouter as ArticleRouterBarclays } from '@/router/whitelabel/barclays/ArticleRouter';

// BofA whitelabel routers
import { ArticleRouter as ArticleRouterBofA } from '@/router/whitelabel/bofa/ArticleRouter';
import { AdminRouter as AdminRouterBofA } from '@/router/whitelabel/bofa/AdminRouter';
import { AnalyticsRouter as AnalyticsRouterBofA } from '@/router/whitelabel/bofa/AnalyticsRouter';
import { PerformanceContributionRouter as PerformanceContributionRouterBofA } from '@/router/whitelabel/bofa/PerformanceRouter';
import { StrategiesRouter as StrategiesRouterBofA } from '@/router/whitelabel/bofa/StrategiesRouter';
import { PortfoliosRouter as PortfoliosRouterBofA } from '@/router/whitelabel/bofa/PortfoliosRouter';
import { DataRouter as DataRouterBofA } from '@/router/whitelabel/bofa/DataRouter';
import { ResourcesRouter as ResourcesRouterBofA } from '@/router/whitelabel/bofa/ResourcesRouter';
import { MyLabRouter as MyLabRouterBofA } from '@/router/whitelabel/bofa/MyLabRouter';
import { SettingsRouter as SettingsRouterBofA } from '@/router/whitelabel/bofa/SettingsRouter';

/**
 * List of foundation routes.
 *
 * These routes satisfies following criteria:
 *   1. Must exist in all platforms
 *   2. Has nothing to do with business logic
 *   3. Router (or to a certain extend, all components) expects these routes to
 *      exist
 *
 * Example includes 404 route and auth routes.
 */
function foundation(): RouteConfig[] {
  return [
    {
      name: RouteName.NOT_FOUND,
      path: '/404',
      component: () => import('@/views/404.vue'),
      meta: { title: 'Not Found' },
    },
    ...AuthRouter,
  ];
}

function coreRoutes(): RouteConfig[] {
  return [
    {
      name: RouteName.HOME,
      path: '/',
      redirect: {
        // leave this as DATA. if user is in a faux whitelabel, the `beforeEnter` callback will direct them properly
        name: RouteName.DATA,
      },
    },
    ...AnalyticsRouter,
    ...PerformanceContributionRouter,
    ...StrategiesRouter,
    ...PortfoliosRouter,
    ...DataRouter,
    ...InsightsRouter,
    ...ResourcesRouter,
    ...InactiveRouter,
    ...MyLabRouter,
    ...AdminRouter,
    ...ArticleRouter,
    ...SettingsRouter,
    ...NoCodeDashboardRouter,
  ];
}

function hsbcRoutes(): RouteConfig[] {
  return [
    {
      name: RouteName.HOME,
      path: '/',
      redirect: {
        name: RouteName.ARTICLES,
      },
    },
    ...PortfoliosRouter,
    ...AnalyticsRouter,
    ...PerformanceAttributionRouterHSBC,
    ...StrategiesRouterHSBC,
    ...DataRouterHSBC,
    ...InactiveRouter,
    ...AdminRouterHSBC,
    ...ArticleRouterHSBC,
    ...TermsRouter,
    ...PrivacyPolicyRouter,
    ...AboutRouter,
  ];
}

function barclaysRoutes(): RouteConfig[] {
  return [
    {
      name: RouteName.HOME,
      path: '/',
      redirect: {
        name: RouteName.DATA,
      },
    },

    ...AnalyticsRouter,
    ...PerformanceContributionRouter,
    ...StrategiesRouter,
    ...PortfoliosRouter,
    ...DataRouter,
    ...ResourcesRouter,
    ...InactiveRouter,
    ...MyLabRouter,
    ...AdminRouter,
    ...ArticleRouterBarclays,
    ...SettingsRouter,
  ];
}

function bofaRoutes(): RouteConfig[] {
  return [
    {
      name: RouteName.HOME,
      path: '/',
      redirect: {
        name: RouteName.INSIGHTS,
      },
    },

    ...AnalyticsRouterBofA,
    ...PerformanceContributionRouterBofA,
    ...StrategiesRouterBofA,
    ...PortfoliosRouterBofA,
    ...DataRouterBofA,
    ...ResourcesRouterBofA,
    ...InactiveRouter,
    ...MyLabRouterBofA,
    ...AdminRouterBofA,
    ...ArticleRouterBofA,
    ...SettingsRouterBofA,
  ];
}

/**
 * Get the list of routes for current platform.
 */
export function getRoutes(): RouteConfig[] {
  let routes: RouteConfig[];

  switch (import.meta.env.VITE_CLIENT) {
    case 'Premialab':
      routes = coreRoutes();
      break;
    case 'HSBC':
      routes = hsbcRoutes();
      break;
    case 'Barclays':
      routes = barclaysRoutes();
      break;
    case 'BofA':
      routes = bofaRoutes();
      break;
    default:
      throw new Error(`Unknown client ${import.meta.env.VITE_CLIENT}`);
  }

  return [
    ...foundation(),
    ...routes,
    {
      path: '*',
      redirect: {
        name: RouteName.NOT_FOUND,
      },
    },
  ];
}
