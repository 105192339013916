import { computed } from 'vue';
import { useFeatureFlag } from './useFeatureFlag';
import useEnv from './useEnv';
import usePortfolioTree from './usePortfolioTree';

export function useBasketPermissions() {
  const { hasEquityBasketAccess, hasQISBasketAccess, hasEquityBasketSubmissionAccess, hasQISBasketSubmissionAccess } =
    useFeatureFlag();

  // TODO : Env variables will be removed when we don't have to mock features anymore
  // They will have their own permissions, or be derived from actual permissions in useFeatureFlag
  const { isCoreEnvironment } = useEnv();

  const hasSomeBasketSubmissionAccess = computed(
    () => hasQISBasketSubmissionAccess.value || hasEquityBasketSubmissionAccess.value,
  );

  const { masterPortfolioTree } = usePortfolioTree();

  const hasRebalancingButtonPermissions = computed(() => masterPortfolioTree.value?.parameters?.isRebalanceEligible);

  const hasSomeBasketAccess = computed(() => hasEquityBasketAccess.value || hasQISBasketAccess.value);

  const canSeeBasketRequestManagementPage = computed(() => hasSomeBasketAccess.value);

  const shouldShowAuditTrailButton = computed(() => hasSomeBasketAccess.value && isCoreEnvironment);

  return {
    hasSomeBasketSubmissionAccess,
    hasSomeBasketAccess,
    canSeeBasketRequestManagementPage,
    shouldShowAuditTrailButton,
    hasRebalancingButtonPermissions,
  };
}
