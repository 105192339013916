import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';

export const BasketRouter: RouteConfig[] = [
  {
    name: RouteName.BASKET,
    path: 'basket/:indexUniqueIdentifier?/:substep?',
    meta: {
      title: RouteName.BASKET,
      shouldNavigationPanelBeShown: true,
      shouldApplicationSidebarBeShown: true,
      isMainContentContainerFluid: true,
      permissions: [UserPermission.EQUITY_BASKET_CREATION, UserPermission.QIS_BASKET_CREATION],
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/Basket.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'inner-sidebar': () => import('@/layout/components/sidebar/NavigationPanel.vue'),
      'analysis-step-toolbar': () => import('@/layout/components/toolbars/AnalysisStepToolbar/BasketToolbar.vue'),
    },
  },
];
