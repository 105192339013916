<template>
  <div
    id="index-search-box-scroll-item"
    :class="isItemEnabled ? 'cursor-pointer' : 'suspended-overlay cursor-not-allowed'"
    class="d-grid index-search-box-scroll-item"
    :style="rowStyle"
    data-testid="index-search-box-scroll-item"
    @click.prevent="onRowClick"
  >
    <div class="align-middle">
      <b-form-checkbox
        v-if="options.selectMultiple"
        v-model="isSelected"
        :value="true"
        :disabled="!isItemEnabled"
        class="ml-2 py-1"
      />
      <b-form-radio
        v-else
        v-model="isSelected"
        name="dsb-radio"
        :value="true"
        :disabled="!isItemEnabled"
        class="ml-2 py-1"
      />
    </div>
    <div
      class="d-flex align-items-center overflow-hidden"
      :style="strategyNameStyle"
    >
      <span
        :title="formatIndexName(source)"
        class="text-ellipsis"
      >
        {{ formatIndexName(source) }}
      </span>
    </div>
    <div
      v-for="property in columnsToShow"
      :key="property"
      class="text-capitalize d-flex align-items-center pl-btn overflow-hidden"
    >
      <span
        :title="formatPropDisplay(property, getProperty(source, property))"
        class="text-ellipsis"
      >
        {{ formatPropDisplay(property, getProperty(source, property)) }}
      </span>
    </div>
    <div />
  </div>
</template>

<script lang="ts">
import { DSBOptions } from '@/types/DSBOptions';
import { defineComponent, computed, PropType } from 'vue';
import { BFormCheckbox, BFormRadio } from 'bootstrap-vue';
import { IPortfolioSubset, ISignal, IStrategySubset, SupportedColumn } from '@/types/IndexOptionsTable';
import { getStrategyDisplayCode } from '@/utils/staticInfo';

export default defineComponent({
  name: 'IndexSearchBoxScrollItem',
  components: {
    BFormCheckbox,
    BFormRadio,
  },
  props: {
    source: {
      type: Object as PropType<IStrategySubset | IPortfolioSubset | ISignal>,
      required: true,
    },
    isItemEnabledFn: {
      type: Function as PropType<(source: IStrategySubset | IPortfolioSubset | ISignal) => boolean>,
      required: true,
    },
    isItemSelectedFn: {
      type: Function as PropType<(source: IStrategySubset | IPortfolioSubset | ISignal) => boolean>,
      required: true,
    },
    formatIndexName: {
      type: Function as PropType<(source: IStrategySubset | IPortfolioSubset | ISignal) => string>,
      required: true,
    },
    columnsToShow: {
      type: Array as PropType<SupportedColumn[]>,
      required: true,
    },
    formatPropDisplay: {
      type: Function as PropType<(property: string, prop: string | null | undefined) => string>,
      required: true,
    },
    toggleItems: {
      type: Function as PropType<
        (
          source: IStrategySubset | IPortfolioSubset | ISignal | (IStrategySubset | IPortfolioSubset | ISignal)[],
        ) => void
      >,
      required: true,
    },
    settingsButtonWidth: {
      type: Number,
      required: true,
    },
    dsbNameColumnWidth: {
      type: Number,
      required: true,
    },
    options: {
      type: Object as PropType<DSBOptions>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const strategyNameStyle = {
      // matches button padding
      'padding-left': '1.05rem',
      overflow: 'hidden',
    };

    const rowStyle = computed(() => {
      let columnTemplate = `min-content 5fr 2fr 2fr 2fr ${props.settingsButtonWidth}px`;

      if (props.columnsToShow.length === 2) {
        columnTemplate = `min-content 7fr 5fr 5fr 1fr ${props.settingsButtonWidth}px`;
      }

      return {
        'grid-template-columns': columnTemplate,
      };
    });

    const isItemEnabled = computed(() => props.isItemEnabledFn(props.source));

    const isSelected = computed({
      get() {
        return props.isItemSelectedFn(props.source);
      },
      set(newVal: boolean) {
        emit('update:private-selected', newVal);
      },
    });

    const onRowClick = () => {
      if (isItemEnabled.value) {
        const newVal = props.options.selectMultiple ? [props.source] : props.source;
        props.toggleItems(newVal);
      }
    };

    const getProperty = (source: IStrategySubset | IPortfolioSubset | ISignal, property: SupportedColumn) => {
      /**
       * The if case needs to be repeated once for IPortfolioSubset and once for IStrategySubset so TypeScript can infer
       * the correct types.
       */
      if ('slug' in source) {
        if (property in source) {
          const key = property as SupportedColumn & keyof IPortfolioSubset;
          const value = source[key];
          return value;
        }

        return undefined;
      }

      if ('assetClass' in source) {
        if (property === 'code') {
          return getStrategyDisplayCode(source);
        }

        if (property in source) {
          const key = property as SupportedColumn & keyof IStrategySubset;
          const value = source[key];
          if (Array.isArray(value)) {
            return value.length > 0 ? value.toString() : '-';
          }
          // For handling flagship
          if (typeof value === 'boolean') return value.toString() ?? '-';
          return value;
        }

        return undefined;
      }

      if (property in source) {
        const key = property as SupportedColumn & keyof ISignal;
        const value = source[key];
        return value;
      }

      return undefined;
    };

    return {
      strategyNameStyle,
      rowStyle,
      isItemEnabled,
      isSelected,
      onRowClick,
      getProperty,
    };
  },
});
</script>

<style scoped>
.index-search-box-scroll-item {
  height: 29px;
}
</style>
